import styled, { css } from 'styled-components'
import { Container, Row, Col, Button as Btn } from 'reactstrap'

import arrowBtnIcon from '../images/arrow_btn_icon.svg'
import closeGrayIcon from '../images/close_gray_icon.svg'
import quoteIcon from '../images/parent/quote_icon.svg'
import cloudLayer1 from '../images/home/home_cloud_layer_1.svg'
import cloudLayer2 from '../images/home/home_cloud_layer_2.svg'
import cloudLayer3 from '../images/home/home_cloud_layer_3.svg'
import cloudLayer4 from '../images/home/home_cloud_layer_4.svg'
import cloudLayer5 from '../images/home/home_cloud_layer_5.svg'
import cloudLayer6 from '../images/home/home_cloud_layer_6.svg'
import cloudLayer7 from '../images/home/home_cloud_layer_7.svg'
import cloudLayer8 from '../images/home/home_cloud_layer_8.svg'

export const paddingsMixin = css/* styled */ `
  padding-left: 4.63%;
  padding-right: 4.63%;
`

export const BlockColImage = styled(Col)`
  display: flex;
  ${(props) =>
    props.position === 'left' &&
    `
    @media (min-width: 768px) {
      padding-right: 0;
    }
  `}
  ${(props) =>
    props.position === 'right' &&
    `
    @media (min-width: 768px) {
      order: 2;
      padding-left: 0;
    }
  `}
  & > div {
    width: 100%;
  }
`

export const BlockColDesc = styled(Col)`
  display: flex;
  padding-left: 4.505%;
  ${(props) =>
    props.position === 'left' &&
    `
    order: 1;
  `}
  ${(props) =>
    props.position === 'right' &&
    `

  `}
`

export const buttonStretchedMixin = css/* styled */ `
  width: 100%;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  &:after {
    content: none;
  }
`

export const buttonArrowMixin = css/* styled */ `
  &:hover {
    &:after {
      right: 25px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 30px;
    display: block;
    width: 12px;
    height: 20px;
    background-image: url(${arrowBtnIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    transition: all 0.2s ease;
  }
`

export const expertBlockQuoteMixin = css/* styled */ `
  position: absolute;
  bottom: 0;
  background-color: rgba(212, 242, 251, 0.95);
  color: #7d8182;
  font-size: 16px;
  padding-top: 70px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
  margin-left: 20%;
  margin-right: 20%;
  z-index: 20;
  ${(props) =>
    !props.open &&
    `
    display: none;
  `}
  @media (min-width: 1200px) {
    padding-top: 45px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 45px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 60px;
    margin-top: 25px;
    margin-left: 15px;
    margin-right: 30px;
    margin-bottom: 25px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    background-image: url(${quoteIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
    @media (max-width: 575px) {
      width: 15px;
      height: 15px;
    }
  }
  &:before {
    left: 35px;
    bottom: 45px;
    @media (max-width: 575px) {
      left: 25px;
      bottom: 40px;
    }
  }
  &:after {
    top: 45px;
    right: 35px;
    @media (max-width: 575px) {
      top: 45px;
      right: 25px;
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(${closeGrayIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    @media (max-width: 575px) {
    }
  }
`

export const Button = styled(Btn)`
  ${buttonArrowMixin}
  position: relative;
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-left: 30px;
  padding-right: 70px;
  border: 2px solid ${(props) => props.theme.colorGreenLight};
  border-radius: 0;
  ${(props) =>
    props.bg &&
    `
    background-color: ${props.bg};
  `}
  ${(props) =>
    props.color &&
    `
    border-color: ${props.color};
  `}
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colorGreenLight}!important;
    color: ${(props) => props.theme.colorWhite};
    ${(props) =>
      props.bg &&
      `
      background-color: ${props.bg}!important;
    `}
    ${(props) =>
      props.color &&
      `
      border-color: ${props.color};
    `}
  }
  span {
    ${buttonArrowMixin}
    position: relative;
    padding-left: 70px;
    padding-right: 70px;
  }
`

export const ButtonLink = styled.a`
  position: relative;
  display: inline-block;
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 5px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  @media (max-width: 575px) {
    font-size: 23px;
    text-align: center;
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorWhite};
    text-decoration: none;
    &:before {
      right: 30px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 35px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid ${(props) => props.theme.colorWhite};
    transform: translateY(-50%);
    transition: all 0.2s ease;
  }
`

export const ButtonLinkTransparent = styled(ButtonLink)`
  background-color: transparent;
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 22px;
  padding-left: 45px;
  padding-right: 45px;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorGreenLight};
    &[aria-current='page'] {
      color: ${(props) => props.theme.colorWhite};
    }
    &:before {
      right: 29px;
    }
  }
  &:before {
    right: 25px;
    border-left-color: ${(props) => props.theme.colorGreenLight};
  }
  &[aria-current='page'] {
    background-color: ${(props) => props.theme.colorGreenLight};
    color: ${(props) => props.theme.colorWhite};
    &:before {
      border-left-color: ${(props) => props.theme.colorWhite};
    }
  }
`

export const TitleBlock = styled.h2`
  color: ${(props) => props.theme.colorGreen};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 46px;
  font-weight: 700;
  line-height: 0.85;
  span {
    font-size: 28px;
  }
`

export const StageTitle = styled.h2`
  display: inline-block;
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 34px;
  font-weight: 700;
  padding: 10px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    padding: 8px;
  }
`

export const TitleWithLine = styled.h2`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
  span {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 7%;
      right: 7%;
      bottom: -5px;
      display: block;
      height: 3px;
      ${(props) =>
        props.color &&
        `
        background-color: ${props.color};
      `}
    }
  }
`

export const StageSubTitle = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 44px;
  }
  @media (max-width: 575px) {
    font-size: 36px;
  }
`

export const ListBlock = styled.ul`
  list-style-type: '-';
  padding-left: 10px;
  margin-top: 25px;
  li {
    line-height: 1.724;
    padding-left: 5px;
  }
`

export const ListColorDots = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    &:before {
      background-color: ${(props) => props.theme.colorGreenLight};
    }
    li {
      &:before {
        background-color: ${(props) => props.theme.colorGray};
      }
    }
  }
  li {
    position: relative;
    font-weight: 300;
    padding-left: 20px;
    margin-top: 5px;
    @media (max-width: 575px) {
      font-size: 16px;
      padding-left: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      @media (min-width: 768px) and (max-width: 991px) {
        top: 6px;
        width: 6px;
        height: 6px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        top: 5px;
        width: 5px;
        height: 5px;
      }
      @media (max-width: 575px) {
        top: 6px;
        width: 6px;
        height: 6px;
      }
    }
  }
  strong {
    font-weight: 400;
  }
`

export const cloudsAnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 300px;
  @media (max-width: 575px) {
    height: 380px;
  }
  .layersWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    @keyframes animateClouds {
      0% {
        margin-left: 0;
      }
      100% {
        margin-left: -200%;
      }
    }
    .cloudLayer {
      position: absolute;
      top: 0;
      left: 100%;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @media (max-width: 575px) {
        background-position-y: 30%;
      }
      &.cloudLayer1 {
        background-image: url(${cloudLayer1});
        animation: animateClouds 55s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 40s linear infinite;
        }
      }
      &.cloudLayer2 {
        background-image: url(${cloudLayer2});
        animation: animateClouds 60s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 45s linear infinite;
        }
      }
      &.cloudLayer3 {
        background-image: url(${cloudLayer3});
        animation: animateClouds 65s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 50s linear infinite;
        }
      }
      &.cloudLayer4 {
        background-image: url(${cloudLayer4});
        animation: animateClouds 75s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 55s linear infinite;
        }
      }
      &.cloudLayer5 {
        background-image: url(${cloudLayer5});
        animation: animateClouds 70s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 40s linear infinite;
        }
      }
      &.cloudLayer6 {
        background-image: url(${cloudLayer6});
        animation: animateClouds 65s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 45s linear infinite;
        }
      }
      &.cloudLayer7 {
        background-image: url(${cloudLayer7});
        animation: animateClouds 75s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 50s linear infinite;
        }
      }
      &.cloudLayer8 {
        background-image: url(${cloudLayer8});
        animation: animateClouds 80s linear infinite;
        @media (max-width: 575px) {
          animation: animateClouds 55s linear infinite;
        }
      }
      &.once {
        left: 0;
        &.cloudLayerOnce1 {
          background-image: url(${cloudLayer5});
          animation: animateClouds 70s linear forwards;
          @media (max-width: 575px) {
            animation: animateClouds 40s linear forwards;
          }
        }
        &.cloudLayerOnce2 {
          background-image: url(${cloudLayer6});
          animation: animateClouds 65s linear forwards;
          @media (max-width: 575px) {
            animation: animateClouds 45s linear forwards;
          }
        }
        &.cloudLayerOnce3 {
          background-image: url(${cloudLayer7});
          animation: animateClouds 75s linear forwards;
          @media (max-width: 575px) {
            animation: animateClouds 50s linear forwards;
          }
        }
        &.cloudLayerOnce4 {
          background-image: url(${cloudLayer8});
          animation: animateClouds 80s linear forwards;
          @media (max-width: 575px) {
            animation: animateClouds 55s linear forwards;
          }
        }
      }
    }
  }
`

export const ArticleWrapper = styled.div`
  @media (min-width: 1200px) {
    margin-top: -90px;
  }
`

export const ArticleContainer = styled(Container)`
  @media (min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`

export const ArticlePersonWrapper = styled.div``

export const ArticlePersonPhotoColWrapper = styled(Col)`
  flex: 0 0 150px;
  max-width: 150px;
  @media (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 20px;
  }
`

export const ArticlePersonPhotoWrapper = styled.div`
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-left: 8px;
  padding-right: 8px;
  @media (max-width: 1199px) {
    display: inline-block;
    width: 150px;
  }
`

export const ArticlePersonAboutColWrapper = styled(Col)`
  flex: 0 0 150px;
  max-width: 150px;
  padding-top: 20px;
  @media (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .personInfo {
    line-height: 1;
    @media (max-width: 1199px) {
      display: inline-block;
      text-align: left;
    }
    .personName {
      display: block;
      font-size: 16px;
      line-height: 24px;
    }
    .personPosition {
      color: ${(props) => props.theme.colorBlack};
      display: block;
      font-size: 12px;
      font-weight: 300;
    }
    .title {
      position: relative;
      color: ${(props) => props.theme.colorGreenLight};
      font-family: ${(props) => props.theme.fontBNPPSansCondensed};
      font-size: 20px;
      font-size: 20px;
      font-weight: 700;
      padding-right: 25px;
      text-transform: uppercase;
      &:before {
        content: '';
        position: absolute;
        right: 10px;
        bottom: 6px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 6px solid ${(props) => props.theme.colorGreenLight};
        border-bottom: 6px solid transparent;
        @media (max-width: 1199px) {
          transform: rotate(90deg);
        }
      }
    }
  }
`

export const ArticlePersonDescColWrapper = styled(Col)`
  color: ${(props) => props.theme.colorBlack};
  font-size: 16px;
  font-weight: 300;
  padding-top: 20px;
`

export const ArticlePersonDescWrapper = styled.div`
  font-size: 16px;
  font-weight: 300;
  h2,
  h3 {
    &.noMarginTop {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 25px;
  }
  ul {
    margin: 0;
    padding-left: 15px;
  }
  .imgWrapper {
    width: 100%;
    height: 100%;
    &.imgCenter {
      width: 50%;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .marginTop30 {
    margin-top: 30px;
  }
  .marginTop90 {
    margin-top: 90px;
  }
  .ArticlePersonDescWrapperFlexVC {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 1199px) {
    .marginTop90 {
      margin-top: 30px;
    }
  }
  .noMarginTop {
    margin-top: 0;
  }
`

export const QuoteLineListWrapper = styled(Row)``

export const QuoteLineListItem = styled(Col)`
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
`

export const QuoteLineListItemInnerWrapper = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  border-left: 8px solid #91bd24;
  @media (min-width: 1400px) {
    padding-left: 40px;
    margin-left: 40px;
  }
  @media (max-width: 767px) {
    border-left: 5px solid #91bd24;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    margin-left: 0;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const QuoteLineListTitle = styled.p`
  font-size: 22px;
  font-weight: 700;
`
